import React from "react"
import styled from "styled-components"

const Header = styled.div`
    margin-bottom: 50px;
    text-align: center;
`
const HeaderTitle = styled.h1`
    margin-bottom: 15px;
    font-weight: bold;
    color: black;
`
const HeaderSubtitle = styled.p`
    max-width: 500px;
    font-weight: 400;
    margin:  0 auto;
    font-size: 16px;
    opacity: 0.9;
    line-height: 1.4;
`

const ConfirmationHeader = () => (
    <Header>
        <HeaderTitle>
            Thank you! Your order has been placed
        </HeaderTitle>
        
    </Header>
)

export default ConfirmationHeader
