import React from "react"
import styled from "styled-components"
import moment from "moment"
import { Link } from "gatsby"
import ConfirmationHeader from "./confirmation-header"
import { numberToCurrency } from "../../utils/utils"
import { colours } from "../../style/colours"

const Container = styled.section`
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px;
    text-align: center;

    @media (max-width: 550px) {
        padding: 50px 20px;
    }
`

const OrderDetailTitle = styled.h5`
    font-size: 18px;
`
const OrderCopy = styled.p`
    font-size: ${props => (props.total ? "22px" : "18px")};
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 20px;
`
const QuestionContainer = styled.div`
    padding: 20px;
    background: #fafafa;
    max-width: 500px;
    margin: 0 auto;
`

const WipayConfirmation = ({ order_id, email, name, phone, date, total }) => (
    <Container>
        <ConfirmationHeader />
        {/* <OrderDetailTitle>Your order number is: {order_id}</OrderDetailTitle> */}
        <OrderCopy>
            An email receipt including the details about your order has been
            sent to <b><u>{email}</u></b>
        </OrderCopy>

        <OrderDetailTitle>Your order total:</OrderDetailTitle>
        <OrderCopy total>{numberToCurrency(total)}</OrderCopy>

        <OrderDetailTitle>This order was placed by:</OrderDetailTitle>
        <OrderCopy>
            {name}
            <br />
            {email}
            <br />
            {phone}
            <br />
            {moment(date)
                .format("dddd Do MMMM, YYYY")
                .toString()}
        </OrderCopy>
        <QuestionContainer>
            <OrderDetailTitle>Questions?</OrderDetailTitle>
            <OrderCopy>
                Feel free to contact us via{" "}
                <a style={{color: colours.maroonDark, fontWeight: 500}} href="mailto: lauralee@gradienthealthfitness.com">email</a>
            </OrderCopy>
        </QuestionContainer>
    </Container>
)

export default WipayConfirmation
