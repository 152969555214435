import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import withLocation from "../components/withLocation"
import { Link, navigate } from "gatsby"
import { ProcessOrderInParse, UploadDeviceForTrade } from "../services/parse-bridge"
import Loader from "react-loader-spinner"
import SEO from "../components/seo"
import { colours } from "../style/colours"
import WipayConfirmation from "../components/order-confirmation.js/wipay-confirmation"

const Center = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 80vh;
`

class OrderConfirmationPage extends Component {
    state = {
        isLoading: true,
        failed: false,
    }

    componentDidMount() {
        const { search } = this.props
        const { status, order_id, type } = search

        this.verifyPayment(order_id, status, type)
    }

    verifyPayment = (order_id, status, type) => {
        if (status === "success") {
            this.setState({ isLoading: true })
            ProcessOrderInParse(order_id).then(res => {
                if (res.success) {
                    this.setState({ isLoading: false })
                } else {
                    // alert("We could not complete your transaction.")
                    this.setState({ isLoading: false })
                }
            })
            

        } else if (type === "cash" || type === "bank") {
            this.setState({ isLoading: false })
        } else if (status === "failed") {
            //Transaction failed. Let them try again
            this.setState({ failed: true, isLoading: false })
        }
    }

    render() {
        const { isLoading, failed } = this.state
        const { search } = this.props
        const {
            name,
            email,
            date,
            order_id,
            status,
            total,
            phone,
            transaction_id,
            type,
            hash,
        } = search

        if (isLoading) {
            return (
                <Layout>
                    <Center>
                        <p>Finishing up your order</p>
                        <Loader
                            type="Oval"
                            color={colours.maroonDark}
                            height={60}
                            width={60}
                        />
                    </Center>
                </Layout>
            )
        }

        if (failed) {
            return (
                <Layout>
                    <Center>
                        <h2>Something went wrong with your transaction</h2>
                        <CartButton black onClick={() => navigate("/checkout")}>
                            Retry Order
                        </CartButton>
                        <Link
                            style={{
                                fontFamily: "Muli",
                                color: "red",
                                textDecoration: "none",
                                marginTop: 10,
                            }}
                            to="/"
                        >
                            Cancel Order
                        </Link>
                    </Center>
                </Layout>
            )
        }

        return (
            <Layout>
                <SEO title="Your order has been confirmed" />
                <WipayConfirmation
                    order_id={order_id}
                    name={name}
                    email={email}
                    phone={phone}
                    date={date}
                    total={total}
                />
            </Layout>
        )
    }
}

export default withLocation(OrderConfirmationPage)

const CartButton = styled.button`
  color: white;
  background: ${colours.greenDark};
  border: 1px solid ${colours.greenLight};
  outline: none;
  text-transform: uppercase;
  padding: 8px 15px;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  width: 80%;


  :disabled {
    background: #a7a7a7;
    cursor: not-allowed;
    border: 1px solid #a7a7a7;

    :hover {
      background:  #a7a7a7;
  }
  }

  :hover {
    background: ${colours.greenDark};
    color: white;
  }
`